import './style.scss';
import Button from "../buttonUI/button";
import {goTo} from "../../tool/navigateManager";

const HelloBlock = props => {
    return <div className={'helloBlock'}>
        <div className={'helloBlock__content'}>
            <h1>Разработка сайтов и мобильных <br/>приложений <span>в 2 раза быстрее</span></h1>
            <p>Не тратьте время на разработку стандартного функционала – подключите наши решения и сосредоточьтесь на уникальных задачах вашего проекта!</p>
            <div className={'helloBlock__content__btnLine'}>
                <Button text={'Обсудить проект'} onClick={() => goTo('#contact')} />
            </div>
            <div className={'helloBlock__btns'}>
                <Button text={'Обсудить проект'} onClick={() => goTo('#contact')} />
            </div>
        </div>
    </div>
}

export default HelloBlock;
