import './style.scss';
import {ReactComponent as Decor} from "../../asset/svg/aboutDecor.svg";

const AboutBlock = props => {
    return <div className={'aboutBlock'}>
        <div id={'about'} className={'anchor'} />
        <div className={'aboutBlock__content'}>
            <div>
                <Decor />
            </div>
            <div className={'aboutBlock__content__info'}>
                <h2>О проекте</h2>
                <p>Наш проект представляет собой набор заранее подготовленных комплексных программных решений, специально спроектированных для удобного и быстрого внедрения в разрабатываемые Вами проекты. Наши решения подходят для реализации веб-приложений, мобильных приложений, CLI и др. Благодаря нашему сервису, разработчики могут избежать необходимости тратить время на создание стандартного функционала, который уже был реализован множество раз.</p>
                <p>Подключив наше API и внедряя клиентские модули, разработчики могут сразу начать использовать возможности «из коробки», что существенно ускоряет процесс разработки и упрощает поддержку проекта. Вместо того чтобы писать один и тот же код снова и снова, разработчики могут сконцентрироваться на создании уникального функционала, специфичного для конкретного проекта.</p>
                <p>Проект помогает сэкономить время и ресурсы команды разработчиков, позволяя им более эффективно работать над уникальными задачами проекта.</p>
                <p>Наш продукт финансируется благодаря Фонду содействия инновациям (ФСИ), а также принят в число резидентов Сколково!</p>
            </div>
        </div>
    </div>
}

export default AboutBlock;
