import {useEffect, useState} from "react";

const Input = props => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (props.onChange) props.onChange(value);
    }, [value])

    return <input className={'ui__input'} value={value} placeholder={props.placeholder}
                  onChange={e => {
        setValue(e.target.value);
    }} />
}

export default Input;
