import {useState} from "react";
import './style.scss';
import Button from "../buttonUI/button";
import Input from "../inputUI/input";
import {ReactComponent as Done} from "../../asset/svg/done.svg";

const ContactUsBlock = props => {
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [error, setError] = useState('');
    const [isSended, setIsSended] = useState(false);

    const handleSend = e => {
        e.preventDefault();
        setError('');

        if (name.length === 0 || contact.length === 0) {
            setError('Данные не заполнены')
            return;
        }

        fetch('https://back.yourtar.ru/api/form/answer/', {
            method: 'POST',
            body: JSON.stringify({
                who: name,
                email: contact,
                project: 'ytDevelop',
            })
        }).then(response => {
            setIsSended(true);
        }).catch(err => alert('Неизвестная ошибка. Попробуйте повторить позднее.'))
    }

    return <div className={'contactUsForm'}>
        <div id={'contact'} className={'anchor'} />
        {!isSended ? <div className={'contactUsForm__content'}>
            <h2>Хотите обсудить проект?</h2>
            <p>Оставьте Ваши контакты и мы обязательно с Вами свяжемся!</p>
            <form onSubmit={e => handleSend(e)}>
                <Input placeholder={'Как Вас зовут?'} onChange={val => setName(val)} />
                <Input placeholder={'Телефон, email или др.'} onChange={val => setContact(val)} />
                <span>Нажимая на кнопку, вы соглашаетесь с <a href={'#'}>политикой обработки персональных данных</a></span>
                <Button text={'Оставить заявку'} onClick={e => handleSend(e)} />
                {error && error.length > 0 ? <span className={'error'}>{error}</span> : null}
            </form>
        </div> : <div className={'contactUsForm__content'}>
            <div className={'contactUsForm__content__doneBlock'}>
                <Done />
                <h2>Ваша заявка принята</h2>
                <p>Спасибо за оставленную заявку.<br/>С вами свяжутся в близжайшее время</p>
            </div>
        </div>}
    </div>
}

export default ContactUsBlock;
