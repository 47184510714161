import Header from "./component/Header/header";
import Footer from "./component/Footer/footer";
import HelloBlock from "./component/HelloBlock/helloBlock";
import VariantsBlock from "./component/variantsBlock/variants";
import AboutBlock from "./component/AboutBlock/about";
import AlgorithmBlock from "./component/algorithmBlock/algorithm";
import FAQBlock from "./component/faqBlock/faq";
import ContactUsBlock from "./component/contactUS/contactUs";
import RewardsBlock from "./component/RewardsBlock/rewards";

function App() {
    return <div className="App">
        <Header />
        <HelloBlock />
        <VariantsBlock />
        <AboutBlock />
        <RewardsBlock />
        <AlgorithmBlock/>
        <FAQBlock/>
        <ContactUsBlock />
        <Footer />
    </div>
}

export default App;
