import './style.scss';

const listBusiness = [
    {number: '01', text: 'Снижение стоимости разработки до 60%'},
    {number: '02', text: 'Снижение сроков разработки до 60%'},
    {number: '03', text: 'Гарантированное высокое качество, не уступающее разработке продукта с нуля'},
    {number: '04', text: 'Максимальная гибкость, масштабируемость и отказоустойчивость Ваших продуктов '},
];
const listDevelopers = [
    {number: '01', text: 'Нет необходимости копировать код для «стандартных функций» с других проектов и адаптировать его'},
    {number: '02', text: 'Нет необходимости писать рутинный код, искать и устранять ошибки'},
    {number: '03', text: 'Только творческие задачи по разработке уникального функционала. Остальное на нас'},
    {number: '04', text: 'Опыт работы с качественной микросервисной архитектурой'},
];

const RewardsBlock = props => {
    return <div className={'rewardsBlock'}>
        <div id={'rewards'} className={'anchor'} />
        <div className={'rewardsBlock__content'}>
            <h2>В чем Ваша выгода?</h2>
            <h3>Для бизнеса:</h3>
            <div className={'rewardsBlock__content__blocks'}>
                {listBusiness.map(item => {
                    return <div key={item.number}>
                        <p>{item.text}</p>
                    </div>
                })}
            </div>
            <h3>Для разработчиков:</h3>
            <div className={'rewardsBlock__content__blocks'}>
                {listDevelopers.map(item => {
                    return <div key={item.number}>
                        <p>{item.text}</p>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default RewardsBlock;
