import {useState} from "react";
import {ReactComponent as Plus} from "../../asset/svg/plus.svg";

const Collapse = props => {
    const [isOpen, setIsOpen] = useState(false);
    return <div className={'ui__collapse'}>
        <div className={'ui__collapse__header pointer' + (isOpen ? ' opened' : '')} onClick={() => setIsOpen(!isOpen)}>
            <span>{props.question}</span>
            <div>
                <Plus />
            </div>
        </div>
        {isOpen ? <div className={'ui__collapse__content'}>{props.answer}</div> : null}
    </div>
}

export default Collapse;
