import './style.scss';
import {ReactComponent as Web} from "../../asset/svg/web.svg";
import {ReactComponent as Mobile} from "../../asset/svg/mobile.svg";
import {ReactComponent as Modules} from "../../asset/svg/modules.svg";

const VariantsBlock = props => {
    return <div className={'variantsBlock'}>
        <div className={'variantsBlock__content'}>
            <div>
                <Web />
                <span>Разработаем сайт</span>
            </div>
            <div>
                <Modules />
                <span>Подключим необходимые решения</span>
            </div>
            <div>
                <Mobile />
                <span>Создадим мобильное приложение</span>
            </div>
        </div>
    </div>
}

export default VariantsBlock
