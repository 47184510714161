import './style.scss';
import {ReactComponent as Decor} from "../../asset/svg/faqDecor.svg";
import Collapse from "../collapse/collapse";

const list = [
    {question: 'Как это работает?', answer: 'Вы подключаетесь к нам и используете готовый функционал. При этом Вам нужно разработать только уникальные дл Вашего проекта возможности! Остальное мы берем на себя.'},
    {question: 'За счет чего разработка серверной части становится быстрее?', answer: 'Для серверной части разработка быстрее за счет использования готовых микросервисов. Внешние сервисы уже запущены и готовы к работе.'},
    {question: 'Как мы ускорим разработку веб- или мобильных приложений?', answer: 'Готовые пакеты для установки позволяют не разрабатывать часть функционала, а просто взять готовый с уже интегрированными запросами к серверной части.'},
    {question: 'Влияет ли Ваш технологический стек на интеграцию нашего инструмента?', answer: 'Для интеграции на серверной стороне - нет, не влияет, т.к. все разработано с применением лучших практик в рамках микросервисной архитектуры. А пакеты для веб- и мобильных приложений созданы только для технологий ReactJS и React Native соответственно.'},
];

const FAQBlock = props => {
    return <div className={'FAQBlock'}>
        <div className={'FAQBlock__content'}>
            <div className={'FAQBlock__content__info'}>
                <h2>Частозадаваемые вопросы</h2>
                {list.map((item, key) => {
                    return <Collapse key={key} question={item.question} answer={item.answer} />
                })}
            </div>
            <div>
                <Decor />
            </div>
        </div>
    </div>
}

export default FAQBlock;
