import {useState} from "react";
import {ReactComponent as Logo} from "../../asset/svg/ytLogo.svg";
import {ReactComponent as Phone} from "../../asset/svg/phone.svg";
import {ReactComponent as Burger} from "../../asset/svg/burger.svg";
import {ReactComponent as Close} from "../../asset/svg/close.svg";
import './style.scss';
import {menuPoints} from "../../asset/data/menu";
import {goTo} from "../../tool/navigateManager";

const Header = props => {
    const [isOpen, setIsOpen] = useState(false);

    if (isOpen) return <div className={'header__openMenu'}>
        <div className={'header__openMenu__close'}>
            <span>МЕНЮ</span>
            <Close className={'pointer'} onClick={() => setIsOpen(!isOpen)} />
        </div>
        <div className={'header__openMenu__points'}>
            {menuPoints.map((item, key) => {
                return <a key={key} href={item.link} onClick={(e) => goTo(item.link, e)}><span>{item.title}</span></a>
            })}
        </div>
    </div>
    return <header>
        <div className={'header__margin'}/>
        <div className={'header__fixed'}>
            <div className={'header__fixed__content'}>
                <div className={'header__fixed__content__logo'}>
                    <a className={'pointer'} href={'#'}><Logo/></a>
                </div>
                <div className={'header__fixed__content__menu'}>
                    {menuPoints.map((item, key) => {
                        return <a key={key} href={item.link} onClick={(e) => goTo(item.link, e)}>{item.title}</a>
                    })}
                </div>
                <div className={'header__fixed__content__phone'} onClick={() => {

                }}>
                    <a href={'tel:+79996200146'} className={'pointer'}>
                        <Phone/>
                        <span>+7 (999) 620-01-46</span>
                    </a>
                    <Burger className={'header__fixed__content__burger pointer'} onClick={() => setIsOpen(!isOpen)} />
                </div>
            </div>
        </div>
    </header>
}

export default Header;
