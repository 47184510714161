import './style.scss';

const list = [
    {number: '01', text: 'Подключаетесь к нам'},
    {number: '02', text: 'Выбираете необходимые готовые решения для бизнес-задач'},
    {number: '03', text: 'Интегрируете их в ваш проект с уникальным функционалом – это происходит очень быстро'},
    {number: '04', text: 'Запускаете ваш продукт быстро и без потерь в качестве'},
];

const AlgorithmBlock = props => {
    return <div className={'algorithmBlock'}>
        <div id={'steps'} className={'anchor'} />
        <div className={'algorithmBlock__content'}>
            <h2>Алгоритм взаимодействия</h2>
            <div className={'algorithmBlock__content__blocks'}>
                {list.map(item => {
                    return <div key={item.number}>
                        <h3>{item.number}</h3>
                        <p>{item.text}</p>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default AlgorithmBlock;
